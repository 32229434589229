<template>
  <div v-if="banners[activeBanner]" id="image-slider" class="relative overflow-hidden flex items-center justify-center">
    <div v-if="banners[activeBanner].image" class="absolute top-0 bottom-0 right-0 left-0">
      <transition name="fade" mode="out-in">
        <img
          :key="banners[activeBanner].image"
          :src="banners[activeBanner].image"
          :alt="banners[activeBanner].title"
          class="h-full w-full object-cover"
        >
      </transition>
    </div>
    <nav class="slider-nav">
      <button aria-label="Vorige slide" class="slider-nav--left focus:outline-none" @click="prev">
        <font-awesome-icon :icon="['far', 'chevron-left']"/>
      </button>
      <button aria-label="Volgende slide" class="slider-nav--right focus:outline-none" @click="next">
        <font-awesome-icon :icon="['far', 'chevron-right']"/>
      </button>
    </nav>
    <transition name="fade" mode="out-in">
      <div :key="banners[activeBanner].title" class="slider-content relative w-3/4">
        <h1 class="slider-content--title">
          {{ banners[activeBanner].title }}
        </h1>
        <div class="w-full md:w-1/2 flex justify-center items-center">
          <nuxt-link :to="banners[activeBanner].button_link" class="btn btn-secondary mt-12">
            {{ banners[activeBanner].button_text }}
          </nuxt-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activeBanner: 0,
  }),
  mounted() {
    setInterval(() => {
      this.next();
    }, 8000);
  },
  methods: {
    next() {
      this.activeBanner < this.banners.length - 1 ? this.activeBanner++ : this.activeBanner = 0;
    },
    prev() {
      this.activeBanner > 0 ? this.activeBanner-- : this.activeBanner = this.banners.length - 1;
    },
  },
};
</script>

<style scoped>
#image-slider {
  @apply relative;
  height: 360px;
  @screen md {
    height: 500px;
  }

  .slider-nav {
    @apply opacity-0 transition duration-300 ease-in-out;

    > button, button:disabled {
      @apply absolute opacity-50 block cursor-pointer text-white transition duration-300 ease-in-out;
      background: rgb(27, 107, 48);
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &:hover {
        @apply opacity-100;
        background: rgb(27, 107, 48);
      }
    }

    &--left {
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
    }

    &--right {
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:hover {

    .slider-nav {
      @apply opacity-100;
    }
  }

  .slider-content {
    @apply flex flex-wrap justify-center;

    &--title {
      @apply w-full text-center text-white;
      transform-origin: 50% 50%;
      transform: perspective(600px);
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
