<template>
  <div class="bg-primary-light">
    <div class="container py-10">
      <h3 class="h2 text-secondary mb-6">Laatste nieuws</h3>

      <section class="flex flex-wrap justify-between">
        <news-item v-for="item in items" :key="item.permalink" :article="item" class="w-full lg:w-1/2"/>
      </section>
    </div>
  </div>
</template>

<script>
import NewsItem from './NewsItem';
export default {
  components: {NewsItem},
  data: () => ({
    items: null,
  }),
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      const {data} = await this.$axios.get('news/recent', {params: {
        limit: 6,
      }});
      this.items = data.data;
    },
  },
};
</script>
