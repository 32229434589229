<template>
  <div class="container py-10">
    <h3 class="h2 text-secondary">Groene Norm producten en diensten</h3>
    <section class="flex flex-wrap justify-center lg:justify-between">
      <product-item v-for="product in computedProduct" :key="product.title" :product="product"/>
    </section>
    <div v-if="pagesCount > 1" class="flex flex-wrap justify-center">
      <div
        v-for="(n, index) in pagesCount"
        :key="n"
        class="px-2 pt-4"
        :style="index !== activePage ? 'opacity: 0.5;' : ''"
        @click="activePage = index"
      >
        <font-awesome-icon :icon="['fas', 'circle']"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from '~/components/products/ProductItem';

export default {
  components: {ProductItem},
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activePage: 0,
  }),
  computed: {
    computedProduct() {
      const startPos = this.activePage * this.perPage;
      return this.products.slice(startPos, (startPos + this.perPage));
    },
    pagesCount() {
      return Math.ceil(this.products.length / this.perPage);
    },
    perPage() {
      if (this.$device.isMobile) {
        return 1;
      } else if (this.$device.isTablet) {
        return 2;
      }
      return 3;
    },
  },
};
</script>
