<template>
  <main>
    <hero-img-slider :banners="page.template.banners"/>
    <green-bar/>
    <product-overview :products="page.template.products"/>
    <latest-news/>
    <green-bar newsletter/>
  </main>
</template>

<script>
import HeroImgSlider from '~/components/base/HeroImgSlider';
import GreenBar from '~/components/base/GreenBar';
import ProductOverview from '~/components/base/ProductOverview';
import LatestNews from '~/components/news/LatestNews';
import page from '~/plugins/mixins/page';

export default {
  components: {LatestNews, ProductOverview, GreenBar, HeroImgSlider},
  mixins: [page],
};
</script>
