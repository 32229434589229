<template>
  <div class="py-10 bg-secondary">
    <div class="h-full container flex flex-wrap justify-center items-center">
      <div v-if="newsletter" class="w-full flex flex-wrap text-center md:text-left justify-center md:justify-between">
        <div class="w-full md:w-2/3 flex flex-wrap items-center pb-6 md:pb-0">
          <div class="w-full font-light text-3xl">Meld u aan voor de <span class="font-bold">Groene Norm</span> nieuwsbrief</div>
          <div class="mt-4">Blijf op de hoogte en schrijf u in voor onze digitale nieuwsbrief.</div>
        </div>
        <div class="flex-shrink flex items-center">
          <nuxt-link to="/nieuwsbrief" class="btn btn-secondary btn-secondary-border">
            Aanmelden
          </nuxt-link>
        </div>
      </div>
      <div v-else class="w-full md:w-2/3 text-center">
        <h2 class="h3 text-white">{{ text }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    newsletter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      text: state => state.settings.tekst,
    }),
  },
};
</script>
