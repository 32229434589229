import {mapState} from 'vuex';

export default {
  async fetch({store, route, error}) {
    try {
      const fullPath = route.fullPath.replace(/^\//, '');

      if (fullPath.includes('wp-includes') || fullPath.endsWith('.php')) {
        throw new Error('Not found');
      }

      await store.dispatch('getPage', fullPath);
    } catch (e) {
      error({statusCode: 404, message: e.message});
    }
  },
  computed: mapState(['page']),
  head() {
    return {
      title: this.page.seo && this.page.seo.meta_title ? this.page.seo.meta_title : this.page.title,
      meta: this.page.seo ? [
        this.page.seo.meta_description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.meta_description,
          }
          : {},
        this.page.seo.meta_index !== undefined
          ? {
            hid: 'robots',
            name: 'robots',
            content: (this.page.seo.meta_follow ? 'follow ' : 'nofollow ') + (this.page.seo.meta_index ? 'index' : 'noindex'),
          }
          : {},
        this.page.seo.meta_title
          ? {
            hid: 'og:title',
            name: 'og:title',
            content: this.page.seo.meta_title,
          } : {},
        this.page.seo.meta_description
          ? {
            hid: 'og:description',
            name: 'og:description',
            content: this.page.seo.meta_description,
          }
          : {},
        this.page.image
          ? {
            hid: 'og:image',
            name: 'og:image',
            content: this.page.image,
          }
          : {},
      ] : [],
    };
  },
};
