<template>
  <nuxt-link :to="product.link" class="item mt-6">
    <img :src="product.image" :alt="product.title" class="object-cover">
    <section class="w-full py-4 px-2 flex flex-col items-center bg-secondary text-center">
      <div class="h3 font-normal">{{ product.title }}</div>
      <div class="w-3/4 text-white pt-2">{{ product.text }}</div>
    </section>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.item {
  @apply cursor-pointer h-auto bg-secondary;
  width: 100%;
  @screen md {
    width: 48%;
  }
  @screen lg {
    width: 32%;
  }
  > img {
    @apply w-full transition duration-300;
    height: 275px;
    &:hover {
      @apply opacity-50;
    }
  }
}
</style>
