<template>
  <article class="flex mb-8">
    <section class="w-1/5">
      <img :src="article.image" :alt="article.title" class="object-cover">
    </section>

    <section class="w-4/5 pl-4 lg:pr-6">
      <nuxt-link :to="{name: 'nieuws-slug', params: {slug: article.permalink}}" class="hover:text-secondary duration-150 block mb-3">
        <span class="h3 leading-none mb-6">{{ article.title }}</span>
      </nuxt-link>

      <section class="mb-3">
        <time datetime="2020-05-08" class="mr-3">
          <font-awesome-icon :icon="['far', 'clock']"/>
          {{ $moment(article.date).format('LL') }}
        </time>
        <nuxt-link :to="{name: 'nieuws', query: {categorie: article.categories[0].slug}}" class="hover:text-secondary duration-150">
          <font-awesome-icon :icon="['far', 'folder-open']"/>
          {{ article.categories[0].name }}
        </nuxt-link>
      </section>

      <section class="lg:pr-3">
        <p>
          {{ article.short_text }}
        </p>
      </section>
    </section>
  </article>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
